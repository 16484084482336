import React, { useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import api from "../helpers/api";
import { PUBLIC_URL, RESET_PASSWORD_PATH } from "../helpers/constants";

export default function ResetPassword() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const username = query.get("username");
  const passwordResetToken = query.get("password_reset_token");

  const validationSchema = yup.object().shape({
    password: yup.string().required("Please enter your new password."),
    confirmPassword: yup.string().when("password", {
      is: (password) => password?.length > 0,
      then: yup
        .string()
        .required("Please re-enter the password.")
        .oneOf([yup.ref("password"), null], "The passwords do not match."),
    }),
  });

  const resetStates = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleSubmit = async (values) => {
    try {
      const response = await api(
        {
          username,
          password: values.password.trim(),
          passwordResetToken,
        },
        RESET_PASSWORD_PATH
      );

      if (response.error) {
        setErrorMessage(response.error);
      } else {
        setSuccessMessage(response.success);
      }
    } catch (error) {
      setErrorMessage(
        "There was an error resetting your password, please try again later."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      resetStates();
      handleSubmit(values);
    },
  });

  return (
    <>
      <Typography component="h1" variant="h4" sx={{ mb: 2, mt: 8 }}>
        Reset Password
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 5 }}>
        Please enter your new password!
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>

            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}

            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                <AlertTitle>{successMessage}</AlertTitle>
              </Alert>
            )}
          </Grid>
        </Grid>
      </form>
      <Link component={RouterLink} to={`/auth/${PUBLIC_URL}`}>
        Back to login page
      </Link>
    </>
  );
}
