const {
  PUBLIC_URL,
  REACT_APP_TOKEN_COOKIE_DOMAIN,
  REACT_APP_TOKEN_COOKIE_EXPIRY,
  REACT_APP_IV_URL,
  REACT_APP_API_URL,
} = process.env;

const PASSWORD_RESET_EMAIL_PATH = "password_reset_email";
const RESET_PASSWORD_PATH = "reset_password";
const LOGIN_PATH = "login";

export {
  PUBLIC_URL,
  REACT_APP_TOKEN_COOKIE_DOMAIN,
  REACT_APP_TOKEN_COOKIE_EXPIRY,
  REACT_APP_IV_URL,
  REACT_APP_API_URL,
  PASSWORD_RESET_EMAIL_PATH,
  RESET_PASSWORD_PATH,
  LOGIN_PATH,
};
