import { REACT_APP_API_URL } from "./constants";

export default async function api(body, path) {
  const response = await fetch(`${REACT_APP_API_URL}/auth/${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const responseJson = await response.json();
  return responseJson;
}
