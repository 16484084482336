import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Footer } from "@consumerig/iv-components";
import { Outlet } from "react-router-dom";
import Logo from "../public/IntelligentVIEW_Logo.svg";
import Splash from "./Splash";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007C8A",
    },
    background: {
      splash: "#D7F1F3",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000DE",
        },
      },
    },
  },
});

export default function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", minWidth: "390px" }}
      >
        <CssBaseline />
        <Grid item xs={12} md={6} component={Paper} margin="auto">
          <Box
            sx={{
              display: "flex",
              mx: "auto",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: { xs: 370 },
            }}
          >
            <Box component="img" alt="IntelligentVIEW" src={Logo} />
            <Outlet />
          </Box>
        </Grid>
        <Splash />
      </Grid>
      <Footer />
    </ThemeProvider>
  );
}
