import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import {
  PUBLIC_URL,
  PASSWORD_RESET_EMAIL_PATH,
  RESET_PASSWORD_PATH,
} from "./helpers/constants";
import Layout from "./components/Layout";
import ResetPassword from "./components/ResetPassword";
import RequestPasswordReset from "./components/RequestPasswordReset";
import Login from "./components/Login";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to={PUBLIC_URL} replace />} />
        <Route path={PUBLIC_URL} element={<Layout />}>
          <Route
            path={`${PASSWORD_RESET_EMAIL_PATH}`}
            element={<RequestPasswordReset />}
          />
          <Route path={`${RESET_PASSWORD_PATH}`} element={<ResetPassword />} />
          <Route path="" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
