import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import api from "../helpers/api";
import { PASSWORD_RESET_EMAIL_PATH, PUBLIC_URL } from "../helpers/constants";

export default function RequestPasswordReset() {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const validationSchema = yup.object().shape({
    username: yup
      .string("Please enter your username")
      .required("Username is required"),
  });

  const resetStates = () => {
    setErrorMessage("");
    setSuccess(false);
  };

  const handleSubmit = async (values) => {
    try {
      const response = await api(
        {
          username: values.username.trim(),
        },
        PASSWORD_RESET_EMAIL_PATH
      );

      if (response.error) {
        setErrorMessage(response.error);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setErrorMessage(
        "There was an error processing your password reset request, please try again later."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema,
    onSubmit: (values) => {
      resetStates();
      handleSubmit(values);
    },
  });

  return (
    <>
      <Typography component="h1" variant="h4" sx={{ mb: 2, mt: 8 }}>
        Forgot Password?
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 5 }}>
        Please enter your username!
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>

            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2, width: 1 }}>
                {errorMessage}
              </Alert>
            )}

            {success && (
              <Alert severity="success" sx={{ mb: 2, width: 1 }}>
                <AlertTitle>
                  Please check your email for instructions to reset your
                  password.
                </AlertTitle>
                <p>If you can&apos;t find it, please check your spam folder.</p>
                <p>
                  Not there? Contact your CSM or&nbsp;
                  <Link href="mailto:support@consumerig.com">
                    support@consumerig.com
                  </Link>
                  .
                </p>
              </Alert>
            )}
          </Grid>
        </Grid>
      </form>
      <Link component={RouterLink} to={`/auth/${PUBLIC_URL}`}>
        Back to login page
      </Link>
    </>
  );
}
