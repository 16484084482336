import * as React from "react";
import Grid from "@mui/material/Grid";
import Image from "../public/IntelligentVIEW_Splash.svg";

export default function Splash() {
  return (
    <Grid
      item
      sm={false}
      md={6}
      sx={{
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "background.splash",
      }}
    />
  );
}
